import { Button, Container, Typography, Box, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const navigate = useNavigate();

  const handleButtonClick = (event) => {
    event.preventDefault();
    const code = document.getElementById('code-input').value;
    navigate(`/trip/${code}`);
  };

  return (
    <Box
      sx={{
        backgroundImage: 'url("hero-background.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
      }}
    >
      <Typography variant="h3" component="h1">
        Matkamme alkaa 24.5.2025
      </Typography>
      <Container maxWidth="sm" style={{ marginTop: '20px' }}>
        <TextField
          id="code-input"
          label="Syötä lippukoodisi"
          variant="outlined"
          fullWidth
          style={{ backgroundColor: 'white' }}
        />
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          style={{ marginTop: '10px' }}
          onClick={handleButtonClick}
        >
          Jatka
        </Button>
      </Container>
    </Box>
  );
};

export default Hero;