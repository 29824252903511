import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'

const firebaseConfig = {
  apiKey: "AIzaSyArWi_5Z0ZrI_R-e3DC_JLfysNnJnI6HKg",
  authDomain: "wedding-bcf87.firebaseapp.com",
  projectId: "wedding-bcf87",
  storageBucket: "wedding-bcf87.appspot.com",
  messagingSenderId: "299801199635",
  appId: "1:299801199635:web:26440827b0dc0caf0febe6",
  measurementId: "G-0GJ8HDBPCW"
};

firebase.initializeApp(firebaseConfig)

export const secondaryApp = firebase.initializeApp(firebaseConfig, 'Secondary')

export const auth = firebase.auth()
const provider = new firebase.auth.GoogleAuthProvider()
provider.setCustomParameters({ prompt: 'select_account' })

export const signInWithGoogle = () => auth.signInWithPopup(provider)
export const db = firebase.firestore()

export default firebase