import React, { useState, useEffect } from 'react';
import { auth, db } from './Firebase';
import 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { Button, Container, FormControlLabel, Radio, Typography, TextField, Grid2 } from '@mui/material';
import { createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';

const Trip = () => {
    const [userData, setUserData] = useState(null);
    const [isAttending, setIsAttending] = useState(null);
    const [avecName, setAvecName] = useState('');
    const [allergies, setAllergies] = useState('');
    const [email, setEmail] = useState('');
    const { code } = useParams();

    useEffect(() => {

        const fetchData = async () => {
            try {
                const usersSnapshot = await db.collection('users').get();
                let foundUser = null;

                usersSnapshot.forEach(doc => {
                    if (doc.id.slice(-4) === code) {
                        foundUser = doc.data();
                    }
                });
                if (!foundUser) {
                    const userCredential = await createUserWithEmailAndPassword(auth, email, 'defaultPassword');
                    const userId = userCredential.user.uid;

                    const newUser = {
                        email,
                        firstName: '',
                        lastName: '',
                        avec: false,
                    };
                    await db.collection('users').doc(userId).set(newUser);
                    setUserData(newUser);
                } else {
                    setUserData(foundUser);
                }
            } catch (error) {
                console.error('Error fetching or creating user:', error);
            }
        };

        fetchData();
    }, [code, email]);

    const handleRSVPChange = (event) => {
        setIsAttending(event.target.value);
    };

    const handleAvecNameChange = (event) => {
        setAvecName(event.target.value);
    };

    const handleAllergiesChange = (event) => {
        setAllergies(event.target.value);
    };

    const submitRSVP = async () => {
        if (isAttending === null || !email) {
            alert('Please fill in all required fields.');
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, 'defaultPassword');
            const userId = userCredential.user.uid;
            await sendPasswordResetEmail(auth, email)
                .then(() => {
                    console.log('Password email sent successfully.');
                })
                .catch((error) => {
                    console.error('Error sending password reset email:', error);

                });

            const response = {
                userId,
                name: `${userData.firstName} ${userData.lastName}`,
                avecName,
                allergies,
                attending: isAttending,
            };

            await db.collection('responses').add(response);
            alert('RSVP submitted successfully!');
        } catch (error) {
            console.error('Error creating user or submitting RSVP:', error);
            alert('Error submitting RSVP. Please try again later.');
        }
    };


    return (
        <Container>
            <div className="wedding-rsvp">
                {userData ? (
                    <>
                        <Typography variant="h3" component="h3">
                            Tervetuloa matkalle kanssamme!
                        </Typography>
                        <p>
                            Rakas {userData.firstName},
                        </p>
                        <p>
                            Kutsumme {userData.avec ? (<>teidät </>) : (<>sinut </>)}
                            mukaan meidän yhteiselle matkalle 24.5.2025 Vanhasta Tuomiokirkosta Scandic Hotel Grand Centraliin häidemme merkeissä.
                        </p>
                        <p>Varaathan matkasi viimeistään 1.3.2025.</p>

                        <Grid2 container spacing={2}>
                            <Grid2 item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Radio
                                            id="attending-yes"
                                            name="attending"
                                            value="yes"
                                            onChange={handleRSVPChange}
                                            checked={isAttending === 'yes'}
                                        />
                                    }
                                    label="Kyllä, osallistun"
                                />
                                <FormControlLabel
                                    control={
                                        <Radio
                                            id="attending-no"
                                            name="attending"
                                            value="no"
                                            onChange={handleRSVPChange}
                                            checked={isAttending === 'no'}
                                        />
                                    }
                                    label="En halua osallistua"
                                />
                            </Grid2>
                        </Grid2>
                        <Grid2 container spacing={2}>
                            {isAttending === 'yes' && (
                                <>
                                    <Grid2 item xs={12}>
                                        <TextField
                                            id="email"
                                            label="Sähköposti"
                                            type="email"
                                            fullWidth
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </Grid2>
                                    {userData.avec === true && (
                                        <Grid2 item xs={12}>
                                            <TextField
                                                id="avec-name"
                                                label="Avecin nimi"
                                                fullWidth
                                                value={avecName}
                                                onChange={handleAvecNameChange}
                                            />
                                        </Grid2>
                                    )}
                                    <Grid2 item xs={12}>
                                        <TextField
                                            id="allergies"
                                            label="Allergiat ja ruokarajoitteet"
                                            multiline
                                            fullWidth
                                            value={allergies}
                                            onChange={handleAllergiesChange}
                                        />
                                    </Grid2>
                                </>
                            )}
                        </Grid2>
                        <br />
                        <Button variant="contained" color="secondary" fullWidth onClick={submitRSVP} disabled={isAttending === null}>
                            Lähetä vastaus
                        </Button>
                    </>
                ) : (
                    <p>Lataillaan...</p>
                )}
            </div>
        </Container >
    );
};

export default Trip;