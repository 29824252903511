import React, { useState } from 'react';
import { Button, TextField, Typography, Grid2, Box } from '@mui/material';
import firebase from './Firebase';
import 'firebase/compat/auth';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
  
    const handleLogin = async () => {
      try {
        const user = await firebase.auth().signInWithEmailAndPassword(email, password);   
        console.log('User logged in:', user);
        navigate('/');
      } catch (error) {
        console.error('Login error:', error);
      }
    };
  
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Grid2 container spacing={2}>
          <Grid2 item xs={12}   
   md={6}>
            <Typography variant="h3">Kirjaudu</Typography>
            <TextField
              label="Sähköposti"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Salasana"
              type="password"   
  
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button variant="contained" color="primary"   
   onClick={handleLogin}>
              Kirjaudu
            </Button>   
  
          </Grid2>
        </Grid2>
      </Box>
    );
  }
  
  export default LoginPage;